body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "Terminus", monospace;
  color: #466646;
  scroll-behavior: smooth;
}

.app-wrapper {
  min-height: 100vh;
}

.container {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "navbar"
    "content";
  min-height: 100vh;
  position: relative;
  z-index: 1;
}

.content {
  grid-area: content;
  overflow-y: auto;
  background-color: rgba(8, 8, 8, 0.404);
}

.scrollable-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
}

.scrollable-content section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.seemore {
  color: #ffffff;
  text-decoration: none;
  font-size: 15px;
}

.seemore:hover {
  text-decoration: underline;
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #000000;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #00ff00;
  border-radius: 10px;
  border: 2px solid #000000;
}

::-webkit-scrollbar-thumb:hover {
  background: #00cc00;
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #00ff00 #000000;
}

/* Animation disabled states */
.animations-disabled {
  background-color: black !important;
  color: white !important;
}

#matrix-canvas {
  background-color: black;
}

.animations-disabled a,
.animations-disabled .seemore,
.animations-disabled .underline-link,
.animations-disabled .download-link {
  color: #00ff00 !important;
}

.animations-disabled .terminalBox {
  background-color: #111 !important;
  border-color: #00ff00 !important;
}

.animations-disabled .terminalHeader {
  background-color: #222 !important;
}

.animations-disabled .terminalContent {
  color: #00ff00 !important;
}

/* Responsive Design */
@media (max-width: 768px) {
  .scrollable-content {
    padding: 1rem;
  }
}

.windows-container {
  height: 100vh;
  width: 100vw;
  background-image: url("../images/windowsbg.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
}

/* Desktop Icons */
.desktop-icons {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.desktop-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  color: white;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
}

.desktop-icon:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.desktop-icon span {
  font-size: 12px;
  text-align: center;
}

/* Terminal Windows */
.terminal-window {
  position: absolute;
  background-color: black;
  width: 700px;
  height: 400px;
  border: 1px solid #666;
}

.terminal-titlebar {
  background-color: #1f1f1f;
  padding: 4px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #333;
  height: 28px;
}

.titlebar-left {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #fff;
}

.terminal-icon {
  width: 16px;
  height: 16px;
  color: #fff;
}

.window-controls {
  display: flex;
  gap: 4px;
}

.window-controls button {
  color: #fff;
  background: none;
  border: none;
  padding: 2px 8px;
  cursor: pointer;
}

.window-controls button:hover {
  background-color: #333;
}

.terminal-content {
  padding: 8px;
  color: #fff;
  font-family: "Consolas", monospace;
  font-size: 14px;
  line-height: 1.2;
  margin: 0;
  height: calc(100% - 45px);
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
}

.terminal-line {
  white-space: pre;
  margin: 0;
  padding: 1px 0;
}

.loading-bar {
  color: #0f0;
  margin-top: 10px;
}

/* Windows Taskbar */
.windows-taskbar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background-color: #1f1f1f;
  display: flex;
  align-items: center;
  padding: 0 8px;
  gap: 8px;
  z-index: 1000;
}

.start-button {
  padding: 8px;
  background: none;
  border: none;
  cursor: pointer;
  color: white;
}

.start-button:hover {
  background-color: #333;
}

.search-bar {
  display: flex;
  align-items: center;
  background-color: #333;
  padding: 4px 12px;
  border-radius: 4px;
  width: 200px;
  gap: 8px;
}

.search-bar svg {
  width: 16px;
  height: 16px;
  color: #888;
}

.search-bar span {
  color: #888;
  font-size: 14px;
}

.taskbar-icons {
  display: flex;
  gap: 4px;
}

.taskbar-icons button {
  padding: 8px;
  background: none;
  border: none;
  cursor: pointer;
  color: white;
}

.taskbar-icons button:hover {
  background-color: #333;
}

.system-tray {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
  padding-right: 8px;
}

.system-tray button {
  padding: 4px;
  background: none;
  border: none;
  cursor: pointer;
  color: white;
}

.system-tray button:hover {
  background-color: #333;
}

.system-tray svg {
  width: 16px;
  height: 16px;
}

.time {
  color: white;
  font-size: 14px;
  padding: 4px 12px;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #1a1a1a;
}

::-webkit-scrollbar-thumb {
  background: #444;
  border: 2px solid #1a1a1a;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.terminal-content::-webkit-scrollbar {
  width: 10px;
}

.terminal-content::-webkit-scrollbar-track {
  background: #000;
}

.terminal-content::-webkit-scrollbar-thumb {
  background: #333;
  border: 2px solid #000;
  border-radius: 5px;
}

.terminal-content::-webkit-scrollbar-thumb:hover {
  background: #444;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.terminal-window {
  animation: fade-in 0.2s ease-out;
}

.terminal-line {
  animation: fade-in 0.1s ease-out;
}

/* Custom selection color for terminal text */
.terminal-content::selection {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.terminal-content::-moz-selection {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
}

/* Loading bar styling */
.loading-bar {
  font-family: "Consolas", monospace;
  color: #0f0;
  margin-top: 10px;
  white-space: pre;
}

/* Ensure terminal windows don't get too close to taskbar */
.terminal-window {
  max-height: calc(100vh - 60px);
}

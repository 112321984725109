.resume-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  text-align: center;
}

.resume-image {
  margin-top: 50px;
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.resume-container h1 {
  color: #00ff00;
  margin-bottom: 20px;
}

.resume-container p {
  color: #00ff00;
  margin-bottom: 10px;
}

.download-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #0d6d00;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
}

.download-link:hover {
  background-color: #0a5300;
}

.resbackButton {
  position: absolute;
  top: 30px;
  text-decoration: none;
  color: #00ff00;
  z-index: 2;
}

.resbackButton:hover {
  text-decoration: underline;
}

.resume-content {
  padding: 1rem;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.resume-content h1 {
  color: #ffffff;
  margin-bottom: 2rem;
  font-size: 2rem;
}

.resume-image-container {
  width: fit-content;
  margin: 0 auto 2rem;
  border: 1px solid #00ff00;
  border-radius: 8px;
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.3);
}

.resume-image {
  max-height: 80vh;
  width: auto;
  display: block;
}

.download-section {
  margin-top: 2rem;
}

.download-section p {
  color: #00ff00;
  margin-bottom: 1rem;
}

.download-button {
  display: inline-block;
  padding: 0.8rem 1.5rem;
  background: transparent;
  color: #00ff00;
  border: 1px solid #00ff00;
  border-radius: 4px;
  text-decoration: none;
  font-family: "Courier New", monospace;
  transition: all 0.3s ease;
}

.download-button:hover {
  background: #00ff00;
  color: black;
  box-shadow: 0 0 15px rgba(0, 255, 0, 0.4);
}

@media (max-width: 768px) {
  .resume-image-container {
    padding: 0.5rem;
  }

  .resume-content h1 {
    font-size: 1.5rem;
  }
}
